/* .unbounded-img {
  width: 100%;
} */

.long-section {
  position: relative;
}

.long-section::after {
  content: "";
  background: url("../../assets/img/banners/long-section-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  object-fit: cover;
  filter: brightness(0.25);
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: -10;
  top: 0;
  left: 0;
}
