/* reset */
*::before,
*::after {
  margin: 0;
  padding: 0;
}

/* import Quielab font */
@font-face {
  font-family: qielab;
  src: url("./assets/fonts/qeilab.otf");
}

/* responsive font size */
html {
  font-size: calc(15px + 0.390625vw);
}