.hero {
  background-position: "center center";
  background-repeat: "no-repeat";
  background-size: "cover";
  height: 100vh;
  width: 100vw;
  position: relative;
}

.hero::before {
  content: "";
  background: rgba(0, 0, 0, 0.35);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -11;
}
#ul__link {
  font-weight: 500;
  size: 15px;
  line-height: 29.03px;
}

.hero__stories {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.navlink__item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
}

.hero__social-link,
.hero__social-link:hover,
.hero__social-link:visited {
  font-family: "Inter";
  font-style: normal;
  color: #f1a1a1;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
}

@media (min-width: "640px") {
  #automation__img {
    transform: scale(0.3);
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .lucrezia-logo {
    transform: scale(.7);
  }
}