.features-container {
  position: relative;
}

.features-row {
  row-gap: 4.5rem;
}

.features-heading {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  line-height: 36px;
}

.feature-text {
  font-size: 0.65rem;
  font-family: "Inter", sans-serif;
}

.features-quote {
  font-family: "Italianno", cursive;
  font-size: 40px;
  line-height: 98%;
  inline-size: 30%;
  position: absolute;
  bottom: 2.5%;
  right: 20%;
  border-right: 1px solid #ca7d71;
  border-left: 1px solid #ca7d71;
}

.feature-icon {
  transform: scale(0.8);
}
